import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Outlet } from "react-router-dom"

export function TechRoute({ user }) {
  if (user.admin || user.tech) {
    return <Outlet />
  }
  return (
    <Paper sx={{ minHeight: "100vh" }} elevation={0} square>
      <Typography variant="h5" align="center" sx={{ pt: 4 }}>
        You are not authorised to see this page
      </Typography>
    </Paper>
  )
}

export function CommsRoute({ user }) {
  if (user.admin || user.comms) {
    return <Outlet />
  }
  return (
    <Paper sx={{ minHeight: "100vh" }} elevation={0} square>
      <Typography variant="h5" align="center" sx={{ pt: 4 }}>
        You are not authorised to see this page
      </Typography>
    </Paper>
  )
}

export function QSTRoute({ user }) {
  if (user.admin || user.qst) {
    return <Outlet />
  }
  return (
    <Paper sx={{ minHeight: "100vh" }} elevation={0} square>
      <Typography variant="h5" align="center" sx={{ pt: 4 }}>
        You are not authorised to see this page
      </Typography>
    </Paper>
  )
}
