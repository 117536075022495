import { lazy, Suspense } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import useAuth from "./components/auth"
import Loading from "./components/loading"
import ProtectedRoute from "./components/route-masks/protectedRoute"
import StaffRoute from "./components/route-masks/staffRoute"
import {
  CommsRoute,
  QSTRoute,
  TechRoute
} from "./components/route-masks/specRoute"
import AdminLayout from "./routes/layout"

const Dashboard = lazy(() => import("./routes/Dashboard"))
const Messages = lazy(() => import("./routes/Messages/index"))
const Message = lazy(() => import("./routes/Messages/message"))
const Users = lazy(() => import("./routes/Users/index"))
const User = lazy(() => import("./routes/Users/user"))
const Payments = lazy(() => import("./routes/Payments/index"))
const Exams = lazy(() => import("./routes/Exam/index"))
const Exam = lazy(() => import("./routes/Exam/exam"))
const NewExam = lazy(() => import("./routes/Exam/new"))

export default function App() {
  const { user, staffUpdater, auth, logout } = useAuth()

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute user={user} auth={auth} />}>
          <Route
            element={<StaffRoute user={user} staffUpdater={staffUpdater} />}
          >
            <Route element={<AdminLayout USER={user} logout={logout} />}>
              <Route element={<CommsRoute user={user} />} path="message">
                <Route
                  path=":id"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Message />
                    </Suspense>
                  }
                />
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <Messages />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="user">
                <Route
                  exact
                  path=":id"
                  element={
                    <Suspense fallback={<Loading />}>
                      <User USER={user} />
                    </Suspense>
                  }
                />
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <Users USER={user} />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="payments" element={<TechRoute user={user} />}>
                <Route
                  exact
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <Payments />
                    </Suspense>
                  }
                />
              </Route>
              <Route path="exam" element={<QSTRoute user={user} />}>
                <Route
                  path="new"
                  element={
                    <Suspense fallback={<Loading />}>
                      <NewExam />
                    </Suspense>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <Suspense fallback={<Loading />}>
                      <Exam />
                    </Suspense>
                  }
                />
                <Route
                  path=""
                  element={
                    <Suspense fallback={<Loading />}>
                      <Exams />
                    </Suspense>
                  }
                />
              </Route>
              <Route
                path=""
                element={
                  <Suspense fallback={<Loading />}>
                    <Dashboard user={user} />
                  </Suspense>
                }
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </Router>
  )
}
