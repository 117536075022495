import { useState, useEffect } from "react"
import Chip from "@mui/material/Chip"
import Stack from "@mui/material/Stack"
import styled from "@mui/material/styles/styled"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import { Link as RouterLink, matchPath, useLocation } from "react-router-dom"
import { Container } from "@mui/system"

const useRouteMatch = patterns => {
  const { pathname } = useLocation()

  for (let i = 0; i < patterns.length; i += 1) {
    const pattern = patterns[i]
    const possibleMatch = matchPath(pattern, pathname)
    if (possibleMatch !== null) {
      return possibleMatch
    }
  }

  return null
}

const Chips = styled(({ USER, ...props }) => {
  const [routes, setRoutes] = useState(null)
  const routeMatch = useRouteMatch([
    { path: "exam", end: false },
    { path: "user", end: false },
    { path: "payments", end: false },
    { path: "message", end: false },
    ""
  ])
  const currentTab = routeMatch?.pattern?.path

  useEffect(() => {
    setRoutes(() => {
      let temp = [{ to: "", label: "Dashboard" }]
      if (USER.comms || USER.admin) {
        temp.push({ to: "message", label: "Messages" })
      }
      if (USER.comms || USER.tech || USER.admin) {
        temp.push({ to: "user", label: "Users" })
      }
      if (USER.tech || USER.admin) {
        temp.push({ to: "payments", label: "Payments" })
      }
      if (USER.qst || USER.admin) {
        temp.push({ to: "exam", label: "Exams" })
      }
      return temp
    })
  }, [USER])

  return (
    <Stack sx={{ mr: 2 }} spacing={1} direction="row" {...props}>
      {routes &&
        routes.map((chip, index) => (
          <Chip
            key={index}
            component={RouterLink}
            to={chip.to}
            clickable
            label={chip.label}
            variant={currentTab === chip.to ? "filled" : "outlined"}
          />
        ))}
    </Stack>
  )
})(({ theme }) => ({
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none"
  }
}))

const Separator = styled(Box)(({ theme }) => ({
  ...theme.mixins.toolbar
}))

export default function Nav({ USER, logout }) {
  return (
    <>
      <AppBar>
        <Container>
          <Toolbar disableGutters sx={{ justifyContent: "space-between" }}>
            <Chips USER={USER} />
            <Chip
              onClick={logout}
              clickable
              label="Log Out"
              color="secondary"
              variant="outlined"
            />
          </Toolbar>
        </Container>
      </AppBar>
      <Separator />
    </>
  )
}
