import { Paper } from "@mui/material"
import Container from "@mui/material/Container"
import { Outlet } from "react-router-dom"
import Nav from "../components/nav"

export default function AdminLayout({ USER, logout }) {
  return (
    <>
      <Nav USER={USER} logout={logout} />
      <Paper sx={{ minHeight: "100vh" }} elevation={0} square>
        <Container sx={{ pt: 2 }}>
          <Outlet />
        </Container>
      </Paper>
    </>
  )
}
