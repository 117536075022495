import { useEffect } from "react"
import Paper from "@mui/material/Paper"
import Typography from "@mui/material/Typography"
import { Outlet, useLocation } from "react-router-dom"

export default function StaffRoute({ user, staffUpdater }) {
  const location = useLocation()

  useEffect(() => {
    staffUpdater()
    // eslint-disable-next-line
  }, [location.pathname])

  if (user.admin || user.tech || user.comms) {
    return <Outlet />
  }
  return (
    <Paper sx={{ minHeight: "100vh" }} elevation={0} square>
      <Typography variant="h5" align="center" sx={{ pt: 4 }}>
        You are not authorised to see this page
      </Typography>
    </Paper>
  )
}
