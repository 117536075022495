import { useEffect, useState } from "react"
import axios from "../axiosSetup"

export default function useAuth() {
  const [user, setUser] = useState(undefined)

  useEffect(() => {
    auth()
  }, [])

  const auth = () => {
    axios
      .get("/user/authCheck")
      .then(data => {
        if (data.status === 200) {
          data = data.data
          setUser(user => ({
            ...user,
            email: data.email
          }))
        }
      })
      .catch(err => {
        if (err.response && err.response.status > 400) {
          setUser(null)
        }
      })
  }

  const staffUpdater = () => {
    axios
      .get("/user/permissions")
      .then(data => data.data)
      .then(data => {
        setUser(user => ({
          ...user,
          ...data
        }))
      })
      .catch(err => console.log(err))
  }

  const logout = () => {
    axios.get("/user/logout").then(data => {
      if (data.status === 200) {
        setUser(null)
      }
    })
  }

  const resetAuth = () => {
    setUser(undefined)
  }

  return {
    user: user,
    staffUpdater: staffUpdater,
    auth: auth,
    logout: logout,
    resetAuth: resetAuth
  }
}
