import { useEffect } from "react"
import { Outlet, useLocation } from "react-router-dom"
import Loading from "../loading"

export default function ProtectedRoute({ user, auth }) {
  const location = useLocation()

  useEffect(() => {
    auth()
    const id = setInterval(auth, 60000)
    return () => clearInterval(id)
  }, [location.pathname])

  if (user === undefined) {
    return <Loading />
  } else if (user === null) {
    window.location.replace(
      `${process.env.REACT_APP_ACCOUNT_URL}/login/?service=admin`
    )
    return <></>
  }
  return <Outlet />
}
