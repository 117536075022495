import Paper from "@mui/material/Paper"
import Ellipsis from "./ellipsisAnim"

export default function Loading() {
  return (
    <Paper
      square
      elevation={0}
      sx={{
        display: "flex",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Ellipsis />
    </Paper>
  )
}
