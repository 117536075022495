import { createTheme, responsiveFontSizes } from "@mui/material/styles"

var theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      dark: "#004d4f",
      main: "#066c6e",
      light: "#32b2b5",
      contrastText: "#E1E9D1"
    },
    secondary: {
      dark: "#8a3a2b",
      main: "#c76b4d",
      light: "#e7a582",
      contrastText: "#FFF0E4"
    }
  },
  typography: {
    h1: {
      fontSize: "3.5rem",
      fontWeight: 400
    },
    h2: {
      fontSize: "2.8rem",
      fontWeight: 300
    },
    h3: {
      fontSize: "2.1rem",
      fontWeight: 300
    },
    h4: {
      fontSize: "1.7rem",
      fontWeight: 200
    }
  },
  transitions: {
    easing: {
      nav: "cubic-bezier(0.2, 0.94, 0.36, 1)"
    },
    duration: {
      navIn: 1300,
      navOut: 500
    }
  }
})

theme = responsiveFontSizes(theme, {
  breakpoints: ["sm", "md"],
  factor: 3
})

export default theme
